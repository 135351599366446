import React from 'react';
import PropTypes from 'prop-types';

const ALL_INITIALIZERS = [];

function load(loader) {

  let promise = loader();

  let state = {
    loading: true,
    loaded: null,
    error: null
  };

  state.promise = promise
    .then(loaded => {
      state.loading = false;
      state.loaded = loaded;
      return loaded;
    })
    .catch(err => {
      state.loading = false;
      state.error = err;
      throw err;
    });

  return state;
}

function resolve(obj) {
  return obj.default ?? obj;
}

function render(loaded, props) {
  return React.createElement(resolve(loaded), props);
}

function createLoadableComponent(loadFn, options) {

  if (!options.loading) {
    options.loading = () => null
  }

  let opts = Object.assign(
    {
      loader: null,
      loading: null,
      delay: 200,
      timeout: null,
      render: render,
      modules: null
    },
    options
  );

  let res = null;

  function init() {
    if (!res) {

      const modules = __dynamicImportMiddleWare.trackImports(() => {
        res = loadFn(opts.loader);
      });

      opts.modules = modules;

    }
    return res.promise;
  }

  ALL_INITIALIZERS.push({
    opts,
    init
  });

  return class LoadableComponent extends React.Component {
    
    constructor(props) {
      
      super(props);

      init();

      this.state = {
        error: res.error,
        pastDelay: false,
        timedOut: false,
        loading: res.loading,
        loaded: res.loaded
      };

    }

    static contextTypes = {
      loadable: PropTypes.shape({
        report: PropTypes.func.isRequired
      })
    };

    static preload() {
      return init();
    }

    UNSAFE_componentWillMount() {
      this._loadModule();
    }

    componentDidMount() {
      this._mounted = true;
    }

    _loadModule() {

      if (this.context && this.context.loadable && Array.isArray(opts.modules)) {
        opts.modules.forEach(moduleName => {
          this.context.loadable.report(moduleName);
        });
      }

      if (!res.loading) {
        return;
      }

      let setStateWithMountCheck = (newState) => {
        if (!this._mounted) {
          return;
        }

        this.setState(newState);
      }

      if (typeof opts.delay === 'number') {
        if (opts.delay === 0) {
          this.setState({ pastDelay: true });
        } else {
          this._delay = setTimeout(() => {
            setStateWithMountCheck({ pastDelay: true });
          }, opts.delay);
        }
      }

      if (typeof opts.timeout === "number") {
        this._timeout = setTimeout(() => {
          setStateWithMountCheck({ timedOut: true });
        }, opts.timeout);
      }

      let update = () => {
        setStateWithMountCheck({
          error: res.error,
          loaded: res.loaded,
          loading: res.loading
        });

        this._clearTimeouts();
      };

      res.promise
        .then(() => {
          update();
          return null;
        })
        .catch(err => {
          update();
          return null;
        });
    }

    componentWillUnmount() {
      this._mounted = false;
      this._clearTimeouts();
    }

    _clearTimeouts() {
      clearTimeout(this._delay);
      clearTimeout(this._timeout);
    }

    retry = () => {
      this.setState({ error: null, loading: true, timedOut: false });
      res = loadFn(opts.loader);
      this._loadModule();
    };

    render() {

      if (this.state.loading || this.state.error) {
        return React.createElement(opts.loading, {
          isLoading: this.state.loading,
          pastDelay: this.state.pastDelay,
          timedOut: this.state.timedOut,
          error: this.state.error,
          retry: this.retry
        });
      } else if (this.state.loaded) {
        return opts.render(this.state.loaded, this.props);
      } else {
        return null;
      }
    }
  };
}

function Loadable(opts) {
  return createLoadableComponent(load, opts);
}

class Capture extends React.Component {

  static propTypes = {
    report: PropTypes.func.isRequired
  };

  static childContextTypes = {
    loadable: PropTypes.shape({
      report: PropTypes.func.isRequired
    }).isRequired
  };

  getChildContext = () => ({
    loadable: {
      report: this.props.report
    }
  })

  render = () => React.Children.only(this.props.children)
}

Loadable.Capture = Capture;

Loadable.preloadAll = async () => {

  // keep going till all initializers are handled 
  // (when nested they'll be added while running this loop)
  while(ALL_INITIALIZERS.length) {

    let promises = [];

    while (ALL_INITIALIZERS.length) {
      let init = ALL_INITIALIZERS.pop().init;
      promises.push(init());
    }

    await Promise.allSettled(promises);

  }

}

Loadable.loadSSRComponents = async () => {

  // import all modules needed to hydrate the SSR'd HTML
  await Promise.allSettled((__HYDRATE_DEPENDENCIES__ || []).map(url => import(url)));
  
  // go over all registered lazy components and preload them if needed
  await Promise.allSettled(ALL_INITIALIZERS.map(async (initializer) => {

    // do a dry run of the loader function to see what imports it will call
    const modules = __dynamicImportMiddleWare.trackImports(() => {
      initializer.opts.loader();
    }, {
      runDry: true
    });

    // check if the modules loaded by the loader are also used by the SSR
    if(modules.every(module => __HYDRATE_DEPENDENCIES__.includes(module))) {
      // if so, preload it
      await initializer.init();
    }

  }));

};

export default Loadable;